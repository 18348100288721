<!--
 * @Author: your name
 * @Date: 2021-04-27 18:37:22
 * @LastEditTime: 2021-05-20 00:40:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\components\time.vue
-->
<template>
  <div>
    <van-popup v-model="show" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
      index: "",
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  methods: {
    timemeth(msg, index) {
      this.columns = msg;
      this.index = index;
      this.show = true;
    },
    onConfirm(value) {
      this.show = false;
      this.$emit("timereturn", value.getFullYear()+'-'+(parseInt(value.getMonth())+1)+'-'+value.getDate(), this.index);
    },
  },
};
</script>
<style scoped>
</style>
